@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import "~@flaticon/flaticon-uicons/css/all/all";

* {
    outline: none;
    box-sizing: border-box;
}

:root {
    --theme-bg-color: rgba(16 18 27 / 40%);
    --border-color: rgba(113 119 144 / 25%);
    --theme-color: #f9fafb;
    --inactive-color: rgb(113 119 144 / 78%);
    --body-font: "Poppins", sans-serif;
    --hover-menu-bg: rgba(12 15 25 / 30%);
    --content-title-color: #999ba5;
    --content-bg: rgb(146 151 179 / 13%);
    --button-inactive: rgb(249 250 251 / 55%);
    --dropdown-bg: #21242d;
    --dropdown-hover: rgb(42 46 60);
    --popup-bg: rgb(22 25 37);
    --search-bg: #14162b;
    --overlay-bg: rgba(36, 39, 59, 0.3);
    --scrollbar-bg: rgb(1 2 3 / 40%);
}

.light-mode {
    --theme-bg-color: rgb(255 255 255 / 31%);
    --theme-color: #3c3a3a;
    --inactive-color: #333333;
    --button-inactive: #3c3a3a;
    --search-bg: rgb(255 255 255 / 31%);
    --dropdown-bg: #f7f7f7;
    --overlay-bg: rgb(255 255 255 / 30%);
    --dropdown-hover: rgb(236 236 236);
    --border-color: rgb(255 255 255 / 35%);
    --popup-bg: rgb(255 255 255);
    --hover-menu-bg: rgba(255 255 255 / 35%);
    --scrollbar-bg: rgb(255 253 253 / 57%);
    --content-title-color: --theme-color;
}

html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

.HomeBody {
    font-family: var(--body-font);
    background-image: url(./Images/BG.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em;
    width: 100%;
    height: 100vh;
}

.ContactBody {
    font-family: var(--body-font);
    background-image: url(./Images/BG.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em;
    width: 100%;
    height: 100vh;
}

/* @media screen and (max-width: 480px) {
    body {
        padding: 0.8em;
    }
} */

.video-bg {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.video-bg video {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

img {
    max-width: 100%;
}

.dark-light {
    position: fixed;
    bottom: 50px;
    right: 30px;
    background-color: var(--dropdown-bg);
    box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-radius: 50%;
    z-index: 3;
    cursor: pointer;
}

.dark-light svg {
    width: 24px;
    flex-shrink: 0;
    fill: #ffce45;
    stroke: #ffce45;
    transition: 0.5s;
}

.light-mode .dark-light svg {
    fill: transparent;
    stroke: var(--theme-color);
}

.light-mode .profile-img {
    border: 2px solid var(--theme-bg-color);
}

.light-mode .content-section ul {
    background-color: var(--theme-bg-color);
}

.light-mode .pop-up__title {
    border-color: var(--theme-color);
}

.light-mode .dropdown.is-active ul {
    background-color: rgba(255, 255, 255, 0.94);
}

body.light-mode:before,
body.light-mode .video-bg:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.45) 100%);
    -webkit-backdrop-filter: saturate(3);
    backdrop-filter: saturate(3);
}

.app {
    background-color: var(--theme-bg-color);
    max-width: 1250px;
    max-height: 860px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 14px;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    font-size: 15px;
    font-weight: 500;
}

.header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 58px;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    padding: 0 30px;
    white-space: nowrap;
}

@media screen and (max-width: 480px) {
    .header {
        padding: 0 16px;
    }
}

.header-menu {
    display: flex;
    align-items: center;
    width: 100%;
}

.header-menu a {
    padding: 20px 10px;
    text-decoration: none;
    color: var(--theme-color);
    border-bottom: 2px solid transparent;
    transition: 0.3s;
}

.top-menu {
    display: none;
}

/* @media screen and (max-width: 610px) {
    .header-menu a:not(.main-header-link) {
        display: none;
    }
} */

.header-menu a.is-active,
.header-menu a:hover {
    color: var(--theme-color);
    border-bottom: 2px solid var(--theme-color);
}

.notify {
    position: relative;
}

.notify:before {
    content: "";
    position: absolute;
    background-color: #3a6df0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    right: 20px;
    top: 16px;
}

@media screen and (max-width: 1055px) {
    .notify {
        display: none;
    }
}

.menu-circle {
    width: 15px;
    height: 15px;
    background-color: #f96057;
    border-radius: 50%;
    box-shadow: 24px 0 0 0 #f8ce52, 48px 0 0 0 #5fcf65;
    margin-right: 195px;
    flex-shrink: 0;
}

@media screen and (max-width: 945px) {
    .menu-circle {
        display: none;
    }
}

.search-bar {
    height: 40px;
    display: flex;
    width: 100%;
    /* max-width: 400px; */
    /* padding-left: 16px; */
    border-radius: 26px;
}

.search-bar input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: var(--search-bg);
    border-radius: 26px;
    font-family: var(--body-font);
    font-size: 15px;
    font-weight: 900;
    /* padding: 0 0 0 40px; */
    padding: 20px;
    box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
    /* background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23717790c7'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e"); */
    /* background-size: 14px;
    background-repeat: no-repeat;
    background-position: 16px 48%; */
    color: var(--theme-color);
}

.ReplyCategoryName {
    height: 100%;
    display: flex;
    width: 100%;
    border-radius: 20px;
}

.CategoryName {
    height: 60px;
    display: flex;
    width: 100%;
    /* padding-left: 20px; */
    border-radius: 20px;
}

.CategoryName input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: var(--search-bg);
    border-radius: 40px;
    font-family: var(--body-font);
    font-size: 15px;
    font-weight: 500;
    padding: 0 20px 0 40px;
    box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
    color: var(--theme-color);
}

.search-bar input::-moz-placeholder {
    font-family: var(--body-font);
    color: var(--inactive-color);
    font-size: 15px;
    font-weight: 900;
}

.search-bar input:-ms-input-placeholder {
    font-family: var(--body-font);
    color: var(--inactive-color);
    font-size: 15px;
    font-weight: 900;
}

.search-bar input::placeholder {
    font-family: var(--body-font);
    color: var(--inactive-color);
    font-size: 15px;
    font-weight: 900;
}

.ContactFormInput{
    width: 100%;
    margin-top: 10px;
}

.CategoryForm{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.ReplyForm{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.ContactFormTextarea{
    margin-top: 10px;
    height: 200px;
    display: flex;
    width: 100%;
}

.ContactFormInput input{
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgba(0,0,0,0.2);
    backdrop-filter: blur(20px);
    border-radius: 44px;
    font-family: var(--body-font);
    font-size: 17px;
    font-weight: 900;
    padding: 10px 0 10px 50px;
    box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
    color: var(--theme-color);
}

.ContactFormTextarea textarea{
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgba(0,0,0,0.2);
    backdrop-filter: blur(20px);
    border-radius: 44px;
    font-family: var(--body-font);
    font-size: 17px;
    font-weight: 900;
    padding: 20px;
    box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
    color: var(--theme-color);
}

#ReplyArea{
    width: 100%;
    height: 200px;
    border: none;
    background-color: rgba(0,0,0,0.2);
    backdrop-filter: blur(20px);
    border-radius: 20px;
    font-family: var(--body-font);
    font-size: 17px;
    font-weight: 900;
    padding: 20px;
    box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
    color: var(--theme-color);
}

.ContactForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-profile {
    display: flex;
    align-items: center;
    padding: 0 10px 0 10px;
    margin-left: auto;
    flex-shrink: 0;
}

.header-profile svg {
    width: 22px;
    color: #f9fafb;
    flex-shrink: 0;
}

.notification {
    position: relative;
}

.notification-number {
    position: absolute;
    background-color: #3a6df0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    right: -6px;
    top: -6px;
}

.notification+svg {
    margin-left: 22px;
}

@media screen and (max-width: 945px) {
    .notification+svg {
        display: none;
    }
}

.profile-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    border: 2px solid var(--theme-color);
    margin-left: 22px;
}

.wide .header-menu,
.wide .header-profile {
    display: none;
}

.wide .search-bar {
    max-width: 600px;
    margin: auto;
    transition: 0.4s;
    box-shadow: 0 0 0 1px var(--border-color);
    padding-left: 0;
}

.wide .menu-circle {
    margin-right: 0;
}

.wrapper {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}

.left-side {
    flex-basis: 240px;
    border-right: 1px solid var(--border-color);
    padding: 26px;
    overflow: auto;
    flex-shrink: 0;
}

@media screen and (max-width: 945px) {
    .left-side {
        display: none;
    }
}

.side-wrapper+.side-wrapper {
    margin-top: 20px;
}

.side-title {
    color: var(--inactive-color);
    margin-bottom: 14px;
}

.side-menu {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.side-menu a {
    text-decoration: none;
    color: var(--theme-color);
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 10px;
    font-size: 14px;
    border-radius: 6px;
    transition: 0.3s;
}

.side-menu a:hover {
    background-color: var(--hover-menu-bg);
}

.side-menu svg {
    width: 16px;
    margin-right: 8px;
}

.updates {
    position: relative;
    top: 0;
    right: 0;
    margin-left: auto;
    width: 18px;
    height: 18px;
    font-size: 11px;
}

.main-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    height: 58px;
    flex-shrink: 0;
}

.main-header .header-menu {
    margin-left: 150px;
}

@media screen and (max-width: 1055px) {
    .main-header .header-menu {
        margin: auto;
    }
}

.main-header .header-menu a {
    padding: 20px 24px;
}

.main-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.menu-link-main {
    text-decoration: none;
    color: var(--theme-color);
    padding: 0 30px;
}

@media screen and (max-width: 1055px) {
    .menu-link-main {
        display: none;
    }
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    color: var(--theme-color);
    padding: 20px 40px;
    height: 100%;
    overflow: auto;
    background-color: var(--theme-bg-color);
}

@media screen and (max-width: 510px) {
    .content-wrapper {
        padding: 20px;
    }
}

.content-wrapper-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-image: url("https://www.transparenttextures.com/patterns/cubes.png"), linear-gradient(to right top, #cf4af3, #e73bd7, #f631bc, #fd31a2, #ff3a8b, #ff4b78, #ff5e68, #ff705c, #ff8c51, #ffaa49, #ffc848, #ffe652);
    border-radius: 14px;
    padding: 20px 40px;
}

@media screen and (max-width: 415px) {
    .content-wrapper-header {
        padding: 20px;
    }
}

.content-wrapper.overlay {
    pointer-events: none;
    transition: 0.3s;
    background-color: var(--overlay-bg);
}

.overlay-app {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: all;
    background-color: rgba(36, 39, 59, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}

.overlay-app.is-active {
    visibility: visible;
    opacity: 1;
}

.img-content {
    font-weight: 500;
    font-size: 17px;
    display: flex;
    align-items: center;
    margin: 0;
}

.img-content svg {
    width: 28px;
    margin-right: 14px;
}

.content-text {
    font-weight: 400;
    font-size: 14px;
    margin-top: 16px;
    line-height: 1.7em;
    color: #ebecec;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* text-transform: capitalize; */
}

.content-wrapper-context {
    width: 100%;
}

.content-button {
    background-color: #3a6df0;
    border: none;
    padding: 8px 26px;
    color: #fff;
    border-radius: 20px;
    margin-top: 16px;
    cursor: pointer;
    transition: 0.3s;
    white-space: nowrap;
}

.content-wrapper-img {
    width: 186px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-top: -25px;
    -o-object-position: center;
    object-position: center;
}

@media screen and (max-width: 570px) {
    .content-wrapper-img {
        width: 110px;
    }
}

.content-section {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.content-section-title {
    color: var(--content-title-color);
    margin-bottom: 14px;
}

.content-section ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    background-color: var(--content-bg);
    padding-left: 0;
    margin: 0;
    border-radius: 14px;
    border: 1px solid var(--theme-bg-color);
    cursor: pointer;
}

.content-section ul li {
    list-style: none;
    padding: 10px 18px;
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transition: 0.3s;
}

.content-section ul li:hover {
    background-color: var(--theme-bg-color);
}

.content-section ul li:hover:first-child {
    border-radius: 13px 13px 0 0;
}

.content-section ul li:hover:last-child {
    border-radius: 0 0 13px 13px;
}

.content-section ul li+li {
    border-top: 1px solid var(--border-color);
}

.content-section ul svg {
    width: 28px;
    border-radius: 6px;
    margin-right: 16px;
    flex-shrink: 0;
}

.products {
    display: flex;
    align-items: center;
    width: 150px;
}

@media screen and (max-width: 480px) {
    .products {
        width: 120px;
    }
}

.status {
    margin-left: auto;
    width: 140px;
    font-size: 15px;
    position: relative;
}

@media screen and (max-width: 700px) {
    .status {
        display: none;
    }
}

.status-circle {
    width: 6px;
    height: 6px;
    background-color: #396df0;
    position: absolute;
    border-radius: 50%;
    top: 4px;
    left: -20px;
}

.status-circle.green {
    background-color: #3bf083;
}

.status-button {
    font-size: 15px;
    margin-top: 0;
    padding: 6px 24px;
}

@media screen and (max-width: 390px) {
    .status-button {
        padding: 6px 14px;
    }
}

.status-button.open {
    background: none;
    color: var(--button-inactive);
    border: 1px solid var(--button-inactive);
}

.status-button:not(.open):hover {
    color: #fff;
    border-color: #fff;
}

.content-button:not(.open):hover {
    background: #1e59f1;
}

.menu {
    width: 5px;
    height: 5px;
    background-color: var(--button-inactive);
    border-radius: 50%;
    box-shadow: 7px 0 0 0 var(--button-inactive), 14px 0 0 0 var(--button-inactive);
    margin: 0 12px;
}

@media screen and (max-width: 415px) {
    .adobe-product .menu {
        display: none;
    }
}

.dropdown {
    position: relative;
    height: 53px;
    width: 40px;
    top: -24px;
    display: flex;
    left: -5px;
    background: transparent;
    border: none;
    cursor: pointer;
}

.dropdown ul {
    position: absolute;
    background: var(--dropdown-bg);
    height: 110px;
    width: 120px;
    right: 0;
    top: 20px;
    pointer-events: none;
    opacity: 0;
    transform: translatey(10px);
    transition: all 0.4s ease;
}

.dropdown ul li a {
    text-decoration: none;
    color: var(--theme-color);
    font-size: 12px;
}

.dropdown.is-active ul {
    opacity: 1;
    pointer-events: all;
    transform: translatey(25px);
}

.dropdown.is-active ul li:hover {
    background-color: var(--dropdown-hover);
}

.button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 187px;
    margin-left: auto;
}

@media screen and (max-width: 480px) {
    .button-wrapper {
        width: auto;
    }
}

.pop-up {
    position: absolute;
    padding: 30px 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
    transition: all 0.3s;
    z-index: 10;
    background-color: var(--popup-bg);
    width: 500px;
    visibility: hidden;
    opacity: 0;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    white-space: normal;
}

@media screen and (max-width: 570px) {
    .pop-up {
        width: 100%;
    }
}

.pop-up.visible {
    visibility: visible;
    opacity: 1;
}

.pop-up__title {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pop-up__subtitle {
    white-space: normal;
    margin: 20px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.8em;
}

.pop-up__subtitle a {
    color: var(--theme-color);
}

.content-button-wrapper .content-button.status-button.open.close {
    width: auto;
}

.content-section .close {
    margin-right: 0;
    width: 24px;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
}

.checkbox-wrapper+.checkbox-wrapper {
    margin: 20px 0 40px;
}

.checkbox {
    display: none;
}

.checkbox+label {
    display: flex;
    align-items: center;
}

.checkbox+label:before {
    content: "";
    margin-right: 10px;
    width: 15px;
    height: 15px;
    border: 1px solid var(--theme-color);
    border-radius: 4px;
    cursor: pointer;
    flex-shrink: 0;
}

.checkbox:checked+label:before {
    background-color: #3a6df0;
    border-color: #3a6df0;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpath d='M20 6L9 17l-5-5'/%3e%3c/svg%3e");
    background-position: 50%;
    background-size: 12px;
    background-repeat: no-repeat;
}

.content-button-wrapper {
    margin-top: auto;
    margin-left: auto;
}

.content-button-wrapper .open {
    margin-right: 8px;
}

.apps-card {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% + 20px);
}

.apps-card-answers {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* width: calc(100% + 20px); */
}

.app-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    background-color: var(--content-bg);
    border-radius: 14px;
    border: 1px solid var(--theme-bg-color);
    padding: 20px;
    cursor: pointer;
    transition: 0.4s ease;
    margin-top: 20px;
}

.app-card-img {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app-card-img img {
    height: 100%;
    width: 100%;
    margin-top: 20px;
    border-radius: 20px;
}

/* 
.app-card-category {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    background-color: var(--content-bg);
    border-radius: 14px;
    border: 1px solid var(--theme-bg-color);
    padding: 20px;
    cursor: pointer;
    transition: 0.4s ease;
} */

.app-card-ans {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    background-color: var(--content-bg);
    border-radius: 20px;
    border: 1px solid var(--theme-bg-color);
    padding: 20px;
    cursor: deafult;
    transition: 0.4s ease;
    margin-top: 20px;
}

.app-card-profile {
    display: flex;
    flex-direction: column;
    width: calc(33.3% - 20px);
    font-size: 16px;
    background-color: var(--content-bg);
    border-radius: 14px;
    border: 1px solid var(--theme-bg-color);
    padding: 20px;
    transition: 0.4s ease;
    margin: 10px;
    cursor: default;
}
.app-card-profile:hover {
    transform: scale(1.02);
    background-color: var(--theme-bg-color);
}
.app-card:hover {
    transform: scale(1.02);
    background-color: var(--theme-bg-color);
}
.app-card-ans:hover {
    transform: scale(1.02);
    background-color: var(--theme-bg-color);
}

.app-card svg {
    width: 28px;
    border-radius: 6px;
    margin-right: 12px;
    flex-shrink: 0;
}

/* .app-card+.app-card {
    margin-left: 20px;
} */

.app-card span {
    display: flex;
    align-items: center;
}

.app-card__subtext {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6em;
    margin-top: 20px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
}

.app-card-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-top: 16px;
}

@media screen and (max-width: 1110px) {
    .app-card {
        width: calc(50% - 20px);
    }

    .app-card:last-child {
        margin-top: 20px;
        margin-left: 0px;
    }
}

@media screen and (max-width: 565px) {
    .app-card {
        width: calc(100% - 20px);
        margin-top: 20px;
    }

    .app-card+.app-card {
        margin-left: 0;
    }
}

::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar-bg);
    border-radius: 10px;
}





/*===== GOOGLE FONTS =====*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

/*===== VARIABLES CSS =====*/
:root {
    /*===== Colores =====*/
    --first-color: #9D4BFF;
    --first-color-dark: #23004D;
    --first-color-light: #A49EAC;
    --first-color-lighten: #F2F2F2;

    /*===== Font and typography =====*/
    --body-font: 'Open Sans', sans-serif;
    --h1-font-size: 1.5rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
}

@media screen and (min-width: 768px) {
    :root {
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
    }
}

/*===== BASE =====*/
*,
::before,
::after {
    box-sizing: border-box;
}

/* body{
    background-color: var(--first-color-lighten);
} */

#FormBody {
    background-color: var(--first-color-lighten);
    margin: 0;
    padding: 0;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    color: var(--first-color-dark);
}

h1 {
    margin: 0;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

/*===== LOGIN =====*/
.login {
    display: grid;
    grid-template-columns: 100%;
    height: 100vh;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.login__content {
    display: grid;
}

.login__img {
    justify-self: center;
}

.login__img img {
    width: 310px;
    margin-top: 1.5rem;
}

.login__forms {
    position: relative;
    height: 368px;
}

.loginform_reg {
    position: relative;
    height: 520px;
}

.login__registre,
.login__create {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    background-color: var(--first-color-lighten);
    padding: 2rem 1rem;
    border-radius: 1rem;
    text-align: center;
    box-shadow: 0 8px 20px rgba(35, 0, 77, .2);
    animation-duration: .4s;
    animation-name: animate-login;
}

@keyframes animate-login {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.1, 1.1);
    }

    100% {
        transform: scale(1, 1);
    }
}

.login__title {
    font-size: var(--h1-font-size);
    margin-bottom: 2rem;
}

.login__box {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: .5rem;
    padding: 1.125rem 1rem;
    background-color: #FFF;
    margin-top: 1rem;
    border-radius: .5rem;
}

.login__box_pfp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* grid-template-columns: max-content 1fr;
  column-gap: .5rem; */
    padding: 1.125rem 1rem;
    background-color: #FFF;
    margin-top: 1rem;
    border-radius: .5rem;
}

.login__box_pfp_Submit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1.125rem 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: 1rem;
    border-radius: 20px;
    color: #fff;
    /* cursor: pointer; */
}

.login__icon {
    font-size: 1.5rem;
    color: var(--first-color);
}

.login__input {
    border: none;
    outline: none;
    font-size: var(--normal-font-size);
    font-weight: 700;
    color: var(--first-color-dark);
}

.login__input_submit {
    border: none;
    outline: none;
    font-size: var(--normal-font-size);
    font-weight: 700;
}

.login__input::placeholder {
    font-size: var(--normal-font-size);
    font-family: var(--body-font);
    color: var(--first-color-light);
}

.login__forgot {
    display: block;
    width: max-content;
    margin-left: auto;
    margin-top: .5rem;
    font-size: var(--small-font-size);
    font-weight: 600;
    color: var(--first-color-light);
}

.login__button {
    display: block;
    padding: 1rem;
    margin: 2rem 0;
    background-color: var(--first-color);
    color: #FFF;
    font-weight: 600;
    text-align: center;
    border-radius: .5rem;
    transition: .3s;
}

.login__button:hover {
    background-color: var(--first-color-dark);
}

.login__account,
.login__signin,
.login__signup {
    font-weight: 600;
    font-size: var(--small-font-size);
}

.login__account {
    color: var(--first-color-dark);
}

.login__signin,
.login__signup {
    color: var(--first-color);
    cursor: pointer;
}

.login__social {
    margin-top: 2rem;
}

.login__social-icon {
    font-size: 1.5rem;
    color: var(--first-color-dark);
    margin: 0 1.5rem;
}

/*Show login*/
.block {
    display: block;
}

/*Hidden login*/
.none {
    display: none;
}

/* ===== MEDIA QUERIES =====*/
@media screen and (min-width: 576px) {
    .login__forms {
        width: 348px;
        justify-self: center;
    }
}

@media screen and (min-width: 1024px) {
    .login {
        height: 100vh;
        overflow: hidden;
    }

    .login__content {
        grid-template-columns: repeat(2, max-content);
        justify-content: center;
        align-items: center;
        margin-left: 10rem;
    }

    .login__img {
        display: flex;
        width: 600px;
        height: 588px;
        background-color: var(--first-color-lighten);
        border-radius: 1rem;
        padding-left: 1rem;
    }

    .login__img img {
        width: 390px;
        margin-top: 0;
    }

    .login__registre,
    .login__create {
        left: -11rem;
    }

    .login__registre {
        bottom: -2rem;
    }

    .login__create {
        bottom: -5.5rem;
    }
}

button {
    cursor: pointer;
}







/*===== GOOGLE FONTS =====*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

/*===== VARIABLES CSS =====*/
:root {
    /*===== Colores =====*/
    --first-color: #9D4BFF;
    --first-color-dark: #23004D;
    --first-color-light: #A49EAC;
    --first-color-lighten: #F2F2F2;

    /*===== Font and typography =====*/
    --body-font: 'Open Sans', sans-serif;
    --h1-font-size: 1.5rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
}

@media screen and (min-width: 768px) {
    :root {
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
    }
}

/*===== BASE =====*/
*,
::before,
::after {
    box-sizing: border-box;
}

#FormBody {
    /* background: url("/src/Images/Login_Bg.jpg");
  background-size: cover;
  background-repeat: no-repeat; */
    margin: 0;
    padding: 0;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    color: var(--first-color-dark);
}

h1 {
    margin: 0;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

/*===== LOGIN =====*/
.login {
    display: grid;
    grid-template-columns: 100%;
    height: 100vh;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.login__content {
    display: grid;
}

.login__img {
    justify-self: center;
}

.login__img img {
    width: 310px;
    margin-top: 1.5rem;
}

.login__forms {
    position: relative;
    height: 368px;
}

.loginform_reg {
    position: relative;
    height: 520px;
}

.login__registre,
.login__create {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    background-color: var(--first-color-lighten);
    padding: 2rem 1rem;
    border-radius: 1rem;
    text-align: center;
    box-shadow: 0 8px 20px rgba(35, 0, 77, .2);
    animation-duration: .4s;
    animation-name: animate-login;
}

@keyframes animate-login {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.1, 1.1);
    }

    100% {
        transform: scale(1, 1);
    }
}

.login__title {
    font-size: var(--h1-font-size);
    margin-bottom: 2rem;
}

.login__box {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: .5rem;
    padding: 1.125rem 1rem;
    background-color: #FFF;
    margin-top: 1rem;
    border-radius: .5rem;
}

.login__box_pfp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* grid-template-columns: max-content 1fr;
  column-gap: .5rem; */
    padding: 1.125rem 1rem;
    background-color: #FFF;
    margin-top: 1rem;
    border-radius: .5rem;
}

.login__icon {
    font-size: 1.5rem;
    color: var(--first-color);
}

.login__input {
    border: none;
    outline: none;
    font-size: var(--normal-font-size);
    font-weight: 700;
    color: var(--first-color-dark);
}

.login__input::placeholder {
    font-size: var(--normal-font-size);
    font-family: var(--body-font);
    color: var(--first-color-light);
}

.login__forgot {
    display: block;
    width: max-content;
    margin-left: auto;
    margin-top: .5rem;
    font-size: var(--small-font-size);
    font-weight: 600;
    color: var(--first-color-light);
}

.login__button {
    display: block;
    padding: 1rem;
    margin: 2rem 0;
    background-color: var(--first-color);
    color: #FFF;
    font-weight: 600;
    text-align: center;
    border-radius: .5rem;
    transition: .3s;
}

.login__button:hover {
    background-color: var(--first-color-dark);
}

.login__account,
.login__signin,
.login__signup {
    font-weight: 600;
    font-size: var(--small-font-size);
}

.login__account {
    color: var(--first-color-dark);
}

.login__signin,
.login__signup {
    color: var(--first-color);
    cursor: pointer;
}

.login__social {
    margin-top: 2rem;
}

.login__social-icon {
    font-size: 1.5rem;
    color: var(--first-color-dark);
    margin: 0 1.5rem;
}

/*Show login*/
.block {
    display: block;
}

/*Hidden login*/
.none {
    display: none;
}

/* ===== MEDIA QUERIES =====*/
@media screen and (min-width: 576px) {
    .login__forms {
        width: 348px;
        justify-self: center;
    }
}

@media screen and (min-width: 1024px) {
    .login {
        height: 100vh;
        overflow: hidden;
    }

    .login__content {
        grid-template-columns: repeat(2, max-content);
        justify-content: center;
        align-items: center;
        margin-left: 10rem;
    }

    .login__img {
        display: flex;
        width: 600px;
        height: 588px;
        background-color: var(--first-color-lighten);
        border-radius: 1rem;
        padding-left: 1rem;
    }

    .login__img img {
        width: 390px;
        margin-top: 0;
    }

    .login__registre,
    .login__create {
        left: -11rem;
    }

    .login__registre {
        bottom: -2rem;
    }

    .login__create {
        bottom: -5.5rem;
    }
}












.errorpage {
    background: url("https://images.wallpapersden.com/image/download/purple-sunrise-4k-vaporwave_bGplZmiUmZqaraWkpJRmbmdlrWZlbWU.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    scroll-behavior: smooth;
}

.error-container {
    text-align: center;
    font-size: 106px;
    font-family: "Catamaran", sans-serif;
    font-weight: 800;
    margin: 70px 15px;
}

.error-container>span {
    display: inline-block;
    position: relative;
}

.error-container>span.four {
    width: 136px;
    height: 43px;
    border-radius: 999px;
    background: linear-gradient(140deg,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0.07) 43%,
            transparent 44%,
            transparent 100%),
        linear-gradient(105deg,
            transparent 0%,
            transparent 40%,
            rgba(0, 0, 0, 0.06) 41%,
            rgba(0, 0, 0, 0.07) 76%,
            transparent 77%,
            transparent 100%),
        linear-gradient(to right, #d89ca4, #e27b7e);
}

.error-container>span.four:before,
.error-container>span.four:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 999px;
}

.error-container>span.four:before {
    width: 43px;
    height: 156px;
    left: 60px;
    bottom: -43px;
    background: linear-gradient(128deg,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0.07) 40%,
            transparent 41%,
            transparent 100%),
        linear-gradient(116deg,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0.07) 50%,
            transparent 51%,
            transparent 100%),
        linear-gradient(to top, #99749d, #b895ab, #cc9aa6, #d7969e, #e0787f);
}

.error-container>span.four:after {
    width: 137px;
    height: 43px;
    transform: rotate(-49.5deg);
    left: -18px;
    bottom: 36px;
    background: linear-gradient(to right,
            #99749d,
            #b895ab,
            #cc9aa6,
            #d7969e,
            #e0787f);
}

.error-container>span.zero {
    vertical-align: text-top;
    width: 156px;
    height: 156px;
    border-radius: 999px;
    background: linear-gradient(-45deg,
            transparent 0%,
            rgba(0, 0, 0, 0.06) 50%,
            transparent 51%,
            transparent 100%),
        linear-gradient(to top right,
            #99749d,
            #99749d,
            #b895ab,
            #cc9aa6,
            #d7969e,
            #ed8687,
            #ed8687);
    overflow: hidden;
    animation: bgshadow 5s infinite;
}

.error-container>span.zero:before {
    content: "";
    display: block;
    position: absolute;
    transform: rotate(45deg);
    width: 90px;
    height: 90px;
    background-color: transparent;
    left: 0px;
    bottom: 0px;
    background: linear-gradient(95deg,
            transparent 0%,
            transparent 8%,
            rgba(0, 0, 0, 0.07) 9%,
            transparent 50%,
            transparent 100%),
        linear-gradient(85deg,
            transparent 0%,
            transparent 19%,
            rgba(0, 0, 0, 0.05) 20%,
            rgba(0, 0, 0, 0.07) 91%,
            transparent 92%,
            transparent 100%);
}

.error-container>span.zero:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 999px;
    width: 70px;
    height: 70px;
    left: 43px;
    bottom: 43px;
    background: #fdfaf5;
    box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}

@keyframes bgshadow {
    0% {
        box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
    }

    45% {
        box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    55% {
        box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    100% {
        box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
    }
}

.errorpage {
    font-family: "Montserrat", Helvetica, sans-serif;
    color: #bbb;
}

.errorpage h1 {
    text-align: center;
    margin: 30px 15px;
}

.zoom-area {
    max-width: 490px;
    margin: 30px auto 30px;
    font-size: 19px;
    text-align: center;
}

.link-container {
    text-align: center;
}

a.more-link {
    text-transform: uppercase;
    font-size: 17px;
    background-color: #de7e85;
    padding: 17px 17px;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
    line-height: 1.5;
    text-decoration: none;
    margin-top: 44px;
    letter-spacing: 1px;
    border-radius: 44px;
}

.more-link-button {
    text-transform: uppercase;
    font-size: 17px;
    background-color: #de7e85;
    padding: 17px 17px;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
    line-height: 1.5;
    text-decoration: none;
    margin-top: 44px;
    letter-spacing: 1px;
    border-radius: 44px;
    border: none;
    font-weight: 600;
    cursor: pointer;
}












.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 30px;
    margin-bottom: 80px;
}

.row .col {
    max-width: 100%;
    width: 100%;
    padding: 0 15px;
    flex: 0 0 100%;
}

.row .col .dropdown-container {
    margin: 0 auto;
    margin-bottom: 20px;
}

@media (min-width: 767px) {
    .row .col {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 992px) {
    .row .col {
        flex: 0 0 25%;
        max-width: 25%;
    }
}
.dropdown-container {
    position: relative;
    max-width: 240px;
}

.dropdown-container .dropdown-toggle {
    color: #000000;
    background-color: #ffffff;
    font-size: 17px;
    padding: 18px 20px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    transition: all ease-in-out 0.3s;
}

.dropdown-container .dropdown-toggle label {
    cursor: pointer;
    width: 100%;
}

.dropdown-container .dropdown-toggle:hover,
.dropdown-container .dropdown-toggle:active,
.dropdown-container .dropdown-toggle:focus {
    background-color: #0979ce;
    color: #ffffff;
}

.dropdown-container .dropdown-menu {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
    margin-top: 17px;
    position: absolute;
    left: 0;
    top: 100%;
    display: none;
    background-color: #ffffff;
    z-index: 10;
}

.dropdown-container .dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    border-radius: 10px;
    z-index: 10;
}

.dropdown-container .dropdown-menu a {
    text-decoration: none;
    display: block;
    color: #000000;
    font-size: 15px;
    padding: 16px 20px;
    font-weight: 600;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
    transition: all ease-in-out 0.3s;
}

.dropdown-container .dropdown-menu a:hover,
.dropdown-container .dropdown-menu a:active,
.dropdown-container .dropdown-menu a:focus {
    background-color: #0979ce;
    color: #ffffff;
}

.dropdown-menu,
.dropdown-toggle {
    position: relative;
}

.dropdown-menu::before,
.dropdown-toggle::before {
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    border: 5px solid;
    border-top-color: rgba(0, 0, 0, 0);
    border-left-color: rgba(0, 0, 0, 0);
    margin-top: -2.5px;
    background-color: rgba(0, 0, 0, 0);
    transition: all ease-in-out 0.2s;
}

.dropdown-menu {
    z-index: 10;
    position: relative;
}

.dropdown-menu::before {
    z-index: -1;
    transform: rotate(-135deg);
    top: -4px;
    border-color: #ffffff;
    box-shadow: 1px 1px 4px -2px rgba(0, 0, 0, 0.4);
}

.dropdown-open .dropdown-menu.dropdown-active {
    display: block;
}

.dropdown-container.dropdown-open .dropdown-toggle {
    background-color: #0979ce;
    color: #ffffff;
}

.dropdown-container.dropdown-open .dropdown-toggle:before {
    transform: rotate(-135deg);
}

.dropdown-bordered .dropdown-toggle {
    border: 2px solid #000000;
    border-radius: 10px;
}

.dropdown-bordered .dropdown-toggle:hover,
.dropdown-bordered .dropdown-toggle:active,
.dropdown-bordered .dropdown-toggle:focus {
    background-color: #0979ce;
    color: #ffffff;
    border-color: #ffffff;
}

.dropdown-bordered .dropdown-menu {
    border: 2px solid #000000;
    border-radius: 10px;
}

.dropdown-bordered .dropdown-menu::before {
    border-color: #000000;
}

.dropdown-bordered .dropdown-menu a {
    box-shadow: none;
    border-bottom: 2px solid #000000;
}

.dropdown-bordered .dropdown-menu li:last-child a {
    border-bottom: 0;
}

.dropdown-gradient .dropdown-toggle,
.dropdown-gradient .dropdown-menu,
.dropdown-gradient a {
    background: #ff3131;
    background: -moz-linear-gradient(262deg, #ff3131 11%, #5400ff 100%);
    background: -webkit-linear-gradient(262deg, #ff3131 11%, #5400ff 100%);
    background: linear-gradient(262deg, #ff3131 11%, #5400ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff3131", endColorstr="#5400ff", GradientType=1);
    color: #ffffff;
}

.dropdown-gradient .dropdown-toggle::before {
    color: #ffffff;
}

.dropdown-gradient .dropdown-menu a {
    background: none;
    box-shadow: none;
    color: #ffffff;
}

.dropdown-gradient .dropdown-menu a:hover,
.dropdown-gradient .dropdown-menu a:active,
.dropdown-gradient .dropdown-menu a:focus {
    background: #ff3131;
    background: -moz-linear-gradient(66deg, #ff3131 11%, #5400ff 100%);
    background: -webkit-linear-gradient(66deg, #ff3131 11%, #5400ff 100%);
    background: linear-gradient(66deg, #ff3131 11%, #5400ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff3131", endColorstr="#5400ff", GradientType=1);
}

.dropdown-gradient .dropdown-menu::before {
    box-shadow: none;
    border-color: #ff3131;
}

.dropdown-solid .dropdown-toggle,
.dropdown-solid .dropdown-menu {
    background-color: #0979CE;
    color: #ffffff;
}

.dropdown-solid .dropdown-menu a {
    color: #ffffff;
}

.dropdown-solid .dropdown-menu a:hover,
.dropdown-solid .dropdown-menu a:active,
.dropdown-solid .dropdown-menu a:focus {
    background-color: #ffffff;
    color: #000000;
}

.dropdown-solid .dropdown-menu::before {
    border-color: #0979CE;
}